import React from 'react'
import PropTypes from 'prop-types'
import {graphql, useStaticQuery} from 'gatsby'
import classNames from 'classnames'
import {Box, Button, Typography} from '@material-ui/core'
import {navigate} from 'gatsby-link'

import {CommonProps} from '@utils/types'
import {GlowButtonComponent} from '@components/index'
import {isSSR} from '@utils/gatsby-utils'

import useStyles from './style.hook'

/**
 * HomeHero
 */
const HomeHero: React.FC<CommonProps> = ({id, className}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const {
		title,
		contactButtonText,
		facebookButtonText,
		facebookUrl,
		instagramUrl,
		body,
		images
	} = useStaticQuery(graphql`
		query contentfulHomeHeroQuery {
			contentfulHomeHero {
				title
				contactButtonText
				facebookButtonText
				facebookUrl
				instagramUrl
				body {
					body
				}
				images {
					title
					file {
						url
					}
				}
			}
		}
	`).contentfulHomeHero

	const contactButtonOnClick = () => {
		navigate('/#contact')
	}

	const facebookButtonOnClick = () => {
		if (!isSSR()) {
			window.open(facebookUrl, '_blank')
		}
	}

	const instagramButtonOnClick = () => {
		if (!isSSR()) {
			window.open(instagramUrl, '_blank')
		}
	}

	return (
		<Box
			id={id}
			className={classNames(classes.homeHero, className && className)}
			style={{
				backgroundImage: images
					.map((image: any) => `url(${image.file.url})`)
					.join(',')
			}}
		>
			<Typography className={classes.title} variant="h1">
				{title}
			</Typography>

			<Typography className={classes.body} variant="body1">
				{body.body}
			</Typography>

			<GlowButtonComponent
				className={classes.contactButton}
				variant="contained"
				color="primary"
				onClick={contactButtonOnClick}
			>
				{contactButtonText}
			</GlowButtonComponent>
			<Box className={classes.instagram} onClick={instagramButtonOnClick}>
				<Button className={classes.instagramIcon}>
					<img src="/images/home/instagram.svg" alt="Instagram" />
				</Button>
			</Box>
			<Box className={classes.facebook} onClick={facebookButtonOnClick}>
				<Button className={classes.facebookIcon}>
					<img src="/images/home/facebook.svg" alt="Facebook" />
				</Button>
				<Typography className={classes.facebookText} variant="body1">
					{facebookButtonText}
				</Typography>
			</Box>
		</Box>
	)
}

HomeHero.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string
}

export default HomeHero
