import {makeStyles, Theme} from '@material-ui/core/styles'
import {
	columnWidth,
	columnWidthUpLg,
	columnWidthUpSm,
	gapWidth,
	gapWidthUpLg,
	gapWidthUpSm
} from '@utils/constants'
import Styling from '@utils/types/style-typing'

/**
 * Component-scoped styles which should be used as a hook
 * inside HomeReferences functional component
 */

const useStyles: Styling.US = () => {
	return makeStyles((theme: Theme) => ({
		homeReferences: {
			display: 'grid',
			gridTemplateColumns: 'repeat(12, 1fr)',
			gridAutoRows: 'minmax(min-content, max-content)',

			// Mobile
			columnGap: theme.spacing(30 / 8),
			paddingTop: theme.spacing(75 / 8),

			// Tablet
			[theme.breakpoints.up('sm')]: {
				columnGap: theme.spacing(15 / 8),
				paddingTop: theme.spacing(75 / 8)
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				columnGap: theme.spacing(15 / 8),
				paddingTop: theme.spacing(150 / 8)
			}
		},
		title: {
			gridColumn: '1 / span 12',
			gridRow: '1',
			color: theme.palette.text.primary,
			marginBottom: theme.spacing(40 / 8)
		},
		referencesGrid: {
			gridColumn: '2 / span 11',
			gridRow: '2',
			display: 'grid',
			gridAutoRows: 'minmax(min-content, max-content)',

			// Mobile
			rowGap: theme.spacing(30 / 8),

			// Tablet
			[theme.breakpoints.up('sm')]: {
				rowGap: theme.spacing(30 / 8)
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				rowGap: theme.spacing(45 / 8)
			}
		},
		reference: {
			textDecoration: 'none'
		},
		referenceTitle: {
			position: 'relative',
			color: theme.palette.text.primary,
			letterSpacing: '-0.55px',
			lineHeight: '48px',
			textTransform: 'uppercase',
			cursor: 'pointer',

			'&::before': {
				content: '""',
				position: 'absolute',
				height: '100%',
				width: 2,
				backgroundColor: theme.palette.common.white,
				transition: `transform 0.5s ${theme.transitions.easing.easeInOut}`,
				transform: 'scaleY(0)',

				// Mobile
				left: `calc(-1 * (${columnWidth} + ${gapWidth}))`,

				// Tablet
				[theme.breakpoints.up('sm')]: {
					left: `calc(-1 * (${columnWidthUpSm} + ${gapWidthUpSm}))`
				},
				// Desktop
				[theme.breakpoints.up('lg')]: {
					left: `calc(-1 * (${columnWidthUpLg} + ${gapWidthUpLg}))`
				}
			},

			'&::after': {
				content: '""',
				position: 'absolute',
				bottom: 0,
				left: 0,
				height: 2,
				width: 0,
				backgroundColor: theme.palette.common.white,
				transition: `width 0.5s ${theme.transitions.easing.easeInOut}`
			},

			'&:hover': {
				'&::before': {
					transform: 'scaleY(1)'
				},
				'&::after': {
					width: '100%'
				}
			},

			// Mobile
			fontSize: 26,

			// Tablet
			[theme.breakpoints.up('sm')]: {
				width: 'max-content',
				fontSize: 26
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				width: 'max-content',
				fontSize: 40
			}
		},
		referenceSubtitle: {
			color: theme.palette.text.primary,
			fontSize: 16,
			letterSpacing: '-0.22px',
			lineHeight: '19px',
			textTransform: 'uppercase'
		},
		referenceHoverReveal: {
			position: 'absolute',
			zIndex: -1,
			width: 120,
			height: 175,
			top: 0,
			left: 0,
			pointerEvents: 'none'
		},
		referenceHoverRevealInner: {
			overflow: 'hidden',
			width: '100%',
			height: '100%',
			position: 'relative'
		},
		referenceHoverRevealImg: {
			width: '100%',
			height: '100%',
			position: 'relative',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%'
		}
	}))()
}

export default useStyles
