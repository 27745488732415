import {makeStyles, Theme} from '@material-ui/core/styles'
import Styling from '@utils/types/style-typing'

/**
 * Template-scoped styles which should be used as a hook
 * inside Home functional template
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => ({
		home: {
			// Mobile
			padding: theme.spacing(0, 30 / 8),
			overflowX: 'hidden',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(0, 75 / 8),
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				padding: theme.spacing(0, 150 / 8)
			}
		}
	}))()

export default useStyles
