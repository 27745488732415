import React from 'react'
import {Box, useMediaQuery, useTheme} from '@material-ui/core'

import {ContactComponent, MarginComponent} from '@components/index'
import HomeHero from '@templates/Home/HomeHero'
import HomeAbout from '@templates/Home/HomeAbout'
import HomeServices from '@templates/Home/HomeServices'
import HomeReferences from '@templates/Home/HomeReferences'

import useStyles from './style.hook'

/**
 * Home
 */
const Home: React.FC = () => {
	// Get styles from template-scoped styles hook
	const classes = useStyles()
	const theme = useTheme()

	const mediaUpSm = useMediaQuery(theme.breakpoints.up('sm'))
	const mediaUpLg = useMediaQuery(theme.breakpoints.up('lg'))

	return (
		<Box id="home-template" className={classes.home}>
			<HomeHero />
			{mediaUpLg ? (
				<MarginComponent top={50} />
			) : mediaUpSm ? (
				<MarginComponent top={50} />
			) : (
				<MarginComponent top={0} />
			)}
			<HomeAbout id="over-ons" />
			{mediaUpLg ? (
				<MarginComponent top={150} />
			) : mediaUpSm ? (
				<MarginComponent top={150} />
			) : (
				<MarginComponent top={100} />
			)}
			<HomeServices id="onze-diensten" />
			<MarginComponent top={50} />
			<HomeReferences id="onze-realisaties" />
			{mediaUpLg ? (
				<MarginComponent top={240} />
			) : mediaUpSm ? (
				<MarginComponent top={120} />
			) : (
				<MarginComponent top={100} />
			)}
			<ContactComponent id="contact" />
			{mediaUpLg ? (
				<MarginComponent bottom={240} />
			) : mediaUpSm ? (
				<MarginComponent bottom={120} />
			) : (
				<MarginComponent bottom={100} />
			)}
		</Box>
	)
}

export default Home
