import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {graphql, useStaticQuery} from 'gatsby'
import classNames from 'classnames'
import {Box, Typography} from '@material-ui/core'
import gsap from 'gsap'
import {Link} from 'gatsby'

import {CommonProps} from '@utils/types'
import {BackdropTypographyComponent} from '@components/index'
import {map, lerp, clamp, getMousePos} from './hover-animation-utils'

import useStyles from './style.hook'

/**
 * HomeReferences
 */
const HomeReferences: React.FC<CommonProps> = ({id, className}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const {title, titleBackdrop, references} = useStaticQuery(graphql`
		query contentfulHomeReferencesQuery {
			contentfulHomeReferences {
				title
				titleBackdrop
				references {
					title
					subtitle
					hoverImage {
						file {
							url
						}
					}
					detailSpotlightImage {
						title
						file {
							url
						}
					}
					detailTitle
					detailBody {
						detailBody
					}
					detailBodySecondary {
						detailBodySecondary
					}
					galleryImages {
						title
						file {
							url
						}
					}
				}
			}
		}
	`).contentfulHomeReferences

	// DOM references
	const referenceHoverRevealRef = useRef(null)
	const referenceHoverRevealImgRef = useRef(null)

	// Timeout references
	const onMouseMoveTimeoutRef = useRef(null)
	const resetVelocityTimeoutRef = useRef(null)

	interface AnimatableProperties {
		mousePosition: {x: number; y: number}
		mousePositionCache: {x: number; y: number}
		rotation: number
		rotationCache: number
		brightness: number
		brightnessCache: number
	}

	const animatablePropertiesRef = useRef(null)

	const [animatableProperties, setAnimatableProperties] = useState<
		AnimatableProperties
	>({
		mousePosition: {x: 0, y: 0},
		mousePositionCache: {x: 0, y: 0},
		rotation: 0,
		rotationCache: 0,
		brightness: 1,
		brightnessCache: 1
	})

	const onMouseEnter = (e: any, image: string) => {
		const t1 = gsap.timeline()
		t1.set(referenceHoverRevealImgRef.current, {
			backgroundImage: `url(${image})`
		}).to(referenceHoverRevealRef.current, {
			duration: 0.5,
			autoAlpha: 1
		})
	}

	const onMouseLeave = () => {
		const tl = gsap.timeline()
		tl.to(referenceHoverRevealRef.current, {
			duration: 0.5,
			autoAlpha: 0
		})
	}

	const resetVelocityThrottle = () => {
		if (!resetVelocityTimeoutRef.current) {
			resetVelocityTimeoutRef.current = setTimeout(() => {
				resetVelocityTimeoutRef.current = null

				setAnimatableProperties({
					...animatablePropertiesRef.current,
					rotation: 0,
					rotationCache: 0,
					brightness: 1,
					brightnessCache: 1
				})
			}, 333)
		}
	}

	/**
	 * Throttle events to only run at 30fps
	 */
	const onMouseMoveThrottle = (e: any) => {
		e.persist()
		// ignore resize events as long as an actualResizeHandler execution is in the queue
		if (!onMouseMoveTimeoutRef.current) {
			onMouseMoveTimeoutRef.current = setTimeout(() => {
				onMouseMoveTimeoutRef.current = null

				// Destruct animatable properties
				const {
					mousePosition,
					mousePositionCache,
					rotationCache,
					brightnessCache
				} = animatableProperties

				const _mouseDistanceX = clamp(
					Math.abs(mousePositionCache.x - mousePosition.x),
					0,
					100
				)
				const _direction = {
					x: mousePositionCache.x - mousePosition.x,
					y: mousePositionCache.y - mousePosition.y
				}

				// Mouse position
				const _mousePositionCache = mousePosition
				const _mousePosition = getMousePos(e)

				// Rotation
				const _rotation = map(
					_mouseDistanceX,
					0,
					100,
					0,
					_direction.x < 0 ? 60 : -60
				)

				// Brightness
				const _brightness = map(_mouseDistanceX, 0, 100, 1, 4)

				// const _xCache = lerp(xCache, _x, 0.08)
				// const _yCache = lerp(yCache, _y, 0.08)
				const _rotationCache = lerp(rotationCache, _rotation, 0.08)
				const _brightnessCache = lerp(brightnessCache, _brightness, 0.08)

				// Update state
				setAnimatableProperties({
					mousePosition: _mousePosition,
					mousePositionCache: _mousePositionCache,
					rotation: _rotation,
					rotationCache: _rotationCache,
					brightness: _brightness,
					brightnessCache: _brightnessCache
				})
				resetVelocityThrottle()
			}, 16.5)
		}
	}

	useEffect(() => {
		animatablePropertiesRef.current = animatableProperties
	}, [animatableProperties])

	useEffect(() => {
		// Destruct animatable properties
		const {mousePosition, rotationCache, brightnessCache} = animatableProperties

		const tl = gsap.timeline()
		tl.to(referenceHoverRevealRef.current, {
			x: mousePosition.x,
			y: mousePosition.y,
			rotation: rotationCache,
			filter: `brightness(${brightnessCache})`
		})
	}, [animatableProperties])

	return (
		<div
			id={id}
			className={classNames(classes.homeReferences, className && className)}
		>
			<BackdropTypographyComponent
				className={classes.title}
				backdropText={titleBackdrop}
				propsToDelegate={{variant: 'h2'}}
			>
				{title}
			</BackdropTypographyComponent>

			{references && (
				<Box className={classes.referencesGrid}>
					{references.map((reference: any, i: number) => (
						<Link
							className={classes.reference}
							key={`reference-${i}`}
							to={`/realisatie`}
							state={{
								reference
							}}
						>
							<Box
								onMouseEnter={e =>
									onMouseEnter(e, reference.hoverImage.file.url)
								}
								onMouseLeave={onMouseLeave}
								onMouseMove={onMouseMoveThrottle}
							>
								<Typography className={classes.referenceTitle} variant="h3">
									{reference.title}
								</Typography>
								{reference.subtitle && (
									<Typography
										className={classes.referenceSubtitle}
										variant="body1"
									>
										{reference.subtitle}
									</Typography>
								)}
							</Box>
						</Link>
					))}
					{/* On hover image */}
					<div
						className={classes.referenceHoverReveal}
						ref={referenceHoverRevealRef}
					>
						<Box className={classes.referenceHoverRevealInner}>
							<div
								className={classes.referenceHoverRevealImg}
								ref={referenceHoverRevealImgRef}
							/>
						</Box>
					</div>
				</Box>
			)}
		</div>
	)
}

HomeReferences.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string
}

export default HomeReferences
