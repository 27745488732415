import React from 'react'
import PropTypes from 'prop-types'
import {graphql, useStaticQuery} from 'gatsby'
import classNames from 'classnames'
import {Box, Typography} from '@material-ui/core'

import {CommonProps} from '@utils/types'
import {BackdropTypographyComponent} from '@components/index'

import useStyles from './style.hook'

/**
 * HomeServices
 */
const HomeServices: React.FC<CommonProps> = ({id, className}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const {title, titleBackdrop, services} = useStaticQuery(graphql`
		query contentfulHomeServicesQuery {
			contentfulHomeServices {
				title
				titleBackdrop
				services {
					title
					body {
						body
					}
					image {
						title
						file {
							url
						}
					}
				}
			}
		}
	`).contentfulHomeServices

	return (
		<Box
			id={id}
			className={classNames(classes.homeServices, className && className)}
		>
			<BackdropTypographyComponent
				className={classes.title}
				backdropText={titleBackdrop}
				propsToDelegate={{variant: 'h2'}}
			>
				{title}
			</BackdropTypographyComponent>

			{services && (
				<Box className={classes.servicesGrid}>
					{services.map((service: any, i: number) => (
						<Box key={`service-${i}`} className={classes.service}>
							<Box className={classes.serviceImage}>
								<img src={service.image.file.url} alt={service.image.title} />
							</Box>
							<Typography className={classes.serviceTitle} variant="h3">
								{service.title}
							</Typography>
							<Typography className={classes.serviceBody} variant="body1">
								{service.body.body}
							</Typography>
						</Box>
					))}
				</Box>
			)}
		</Box>
	)
}

HomeServices.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string
}

export default HomeServices
