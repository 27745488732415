import {makeStyles, Theme} from '@material-ui/core/styles'
import Styling from '@utils/types/style-typing'

/**
 * Component-scoped styles which should be used as a hook
 * inside HomeServices functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => ({
		homeServices: {
			display: 'grid',
			gridTemplateColumns: 'repeat(12, 1fr)',
			gridAutoRows: 'minmax(min-content, max-content)',

			// Mobile
			columnGap: theme.spacing(15 / 8),

			// Tablet
			[theme.breakpoints.up('sm')]: {
				columnGap: theme.spacing(15 / 8)
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				columnGap: theme.spacing(30 / 8)
			}
		},
		title: {
			gridColumn: '1 / span 12',
			gridRow: '1',
			color: theme.palette.text.primary,
			marginBottom: theme.spacing(35 / 8)
		},
		servicesGrid: {
			gridColumn: '1 / span 12',
			gridRow: '2',
			display: 'grid',
			columnGap: theme.spacing(30 / 8),
			rowGap: theme.spacing(30 / 8),
			gridAutoRows: 'minmax(min-content, max-content)',

			// Mobile
			gridTemplateColumns: 'repeat(1, 1fr)',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridTemplateColumns: 'repeat(1, 1fr)'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridTemplateColumns: 'repeat(3, 1fr)'
			}
		},
		service: {},
		serviceImage: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'flex-end',
			height: 200,
			marginBottom: theme.spacing(32 / 8),

			'& img': {
				maxHeight: '100%',
				maxWidth: '100%'
			}
		},
		serviceTitle: {
			marginBottom: theme.spacing(16 / 8),
			color: theme.palette.text.primary,
			fontSize: 24,
			letterSpacing: '-0.33px',
			lineHeight: '29px',
			textAlign: 'center'
		},
		serviceBody: {
			color: theme.palette.text.secondary,
			fontSize: 16,
			letterSpacing: '-0.22px',
			lineHeight: '24px',
			textAlign: 'center'
		}
	}))()

export default useStyles
