import {makeStyles, Theme} from '@material-ui/core/styles'
import {
	columnWidth,
	columnWidthUpLg,
	columnWidthUpSm,
	gapWidth,
	gapWidthUpLg,
	gapWidthUpSm
} from '@utils/constants'
import Styling from '@utils/types/style-typing'

/**
 * Component-scoped styles which should be used as a hook
 * inside HomeHero functional component
 */

const useStyles: Styling.US = () => {
	return makeStyles((theme: Theme) => ({
		homeHero: {
			position: 'relative',
			display: 'grid',
			gridTemplateColumns: 'repeat(12, 1fr)',
			gridAutoRows: 'minmax(min-content, max-content)',

			backgroundRepeat: 'no-repeat',

			'&:after': {
				pointerEvents: 'none',
				display: 'none',
				content: '""',
				position: 'absolute',
				top: 0,
				right: 0,
				width: '100%',
				background: [
					`linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 100%)`,
					`linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 100%)`,
					`linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 100%)`
				].join(','),
				backgroundRepeat: 'no-repeat'
			},

			// Mobile
			columnGap: theme.spacing(15 / 8),
			padding: theme.spacing(150 / 8, 0, 0),
			backgroundSize: 0,

			// Tablet
			[theme.breakpoints.up('sm')]: {
				columnGap: theme.spacing(15 / 8),
				padding: theme.spacing(150 / 8, 0, 0),
				marginRight: theme.spacing(-75 / 8),
				backgroundPosition: [
					`right calc((3 * ${columnWidthUpSm}) + (3 * ${gapWidthUpSm}) + 1px) bottom`,
					`right 1px top 225px`
				].join(','),
				backgroundSize: `calc((3 * ${columnWidthUpSm}) + (2 * ${gapWidthUpSm}))`,

				'&:after': {
					display: 'block',
					height: `calc(125% + 50px + 80px)`,
					backgroundSize: ['1px 71%', '1px 86%', '1px 100%'].join(','),
					backgroundPosition: [
						`right calc((3 * ${columnWidthUpSm}) + (3 * ${gapWidthUpSm}) + (3 * ${columnWidthUpSm}) + (3 * ${gapWidthUpSm})) top`,
						`right calc((3 * ${columnWidthUpSm}) + (3 * ${gapWidthUpSm})) top`,
						`right top`
					].join(',')
				}
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				columnGap: theme.spacing(30 / 8),
				padding: theme.spacing(250 / 8, 0, (165 + 16) / 8),
				marginRight: 0,
				backgroundPosition: [
					`right calc((3 * ${columnWidthUpLg}) + (3 * ${gapWidthUpLg}) + 1px) bottom`,
					`right 1px top 125px`
				].join(','),
				backgroundSize: `calc((3 * ${columnWidthUpLg}) + (2 * ${gapWidthUpLg}))`,

				'&:after': {
					display: 'block',
					height: `calc(125% + 50px + 80px)`,
					backgroundSize: ['1px 71%', '1px 86%', '1px 100%'].join(','),
					backgroundPosition: [
						`right calc((3 * ${columnWidthUpLg}) + (3 * ${gapWidthUpLg}) + (3 * ${columnWidthUpLg}) + (3 * ${gapWidthUpLg})) top`,
						`right calc((3 * ${columnWidthUpLg}) + (3 * ${gapWidthUpLg})) top`,
						`right top`
					].join(',')
				}
			},
			// XL Desktop
			[theme.breakpoints.up('xl')]: {
				backgroundPosition: [
					`right calc((2 * ${columnWidthUpLg}) + (3 * ${gapWidthUpLg}) + 1px) bottom`,
					`right 1px top 125px`
				].join(','),
				backgroundSize: `calc((2 * ${columnWidthUpLg}) + (1 * ${gapWidthUpLg}))`,

				'&:after': {
					display: 'block',
					height: `calc(125% + 50px + 80px)`,
					backgroundSize: ['1px 71%', '1px 86%', '1px 100%'].join(','),
					backgroundPosition: [
						`right calc((2 * ${columnWidthUpLg}) + (3 * ${gapWidthUpLg}) + (2 * ${columnWidthUpLg}) + (2 * ${gapWidthUpLg})) top`,
						`right calc((2 * ${columnWidthUpLg}) + (3 * ${gapWidthUpLg})) top`,
						`right top`
					].join(',')
				}
			}
		},
		title: {
			gridRow: '1',
			color: theme.palette.text.primary,

			// Mobile
			gridColumn: 'span 12',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: 'span 10'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: 'span 7'
			}
		},
		body: {
			gridRow: '2',
			margin: theme.spacing(16 / 8, 0, 40 / 8),
			color: theme.palette.text.secondary,
			fontSize: 18,
			letterSpacing: '-0.25px',
			lineHeight: '28px',

			// Mobile
			gridColumn: 'span 12',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: 'span 6'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: 'span 5'
			}
		},
		contactButton: {
			gridRow: '3',

			// Mobile
			gridColumn: 'span 8',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: 'span 4'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: 'span 2'
			}
		},
		facebook: {
			gridRow: '3',
			display: 'flex',
			flexDirection: 'row',
			width: 'fit-content',
			cursor: 'pointer',

			'&:hover': {
				'& .MuiButton-root': {
					backgroundColor: 'rgba(44, 136, 255, 0.24)'
				}
			},

			// Mobile
			gridColumn: 'span 3',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: 'span 5'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: 'span 3'
			}
		},
		facebookIcon: {
			minWidth: 56,
			border: '4px solid rgba(44, 136, 255, 0.24)',
			borderRadius: 28
		},
		facebookText: {
			flexDirection: 'column',
			marginLeft: theme.spacing(12 / 8),
			color: theme.palette.text.primary,
			fontSize: 14,
			letterSpacing: '-0.19px',
			lineHeight: '17px',

			'&::before': {
				content: '"Facebook"',
				fontFamily: 'Poppins, sans-serif',
				color: '#2C88FF',
				fontSize: 10,
				letterSpacing: '-0.14px',
				lineHeight: '12px',
				textTransform: 'uppercase'
			},

			// Mobile
			display: 'none',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				display: 'none'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				display: 'flex'
			}
		},
		instagram: {
			gridRow: '3',
			justifySelf: 'center',
			display: 'flex',
			flexDirection: 'row',
			width: 'fit-content',
			cursor: 'pointer',
			padding: theme.spacing(4 / 8),
			borderRadius: 28,
			background:
				'linear-gradient(0deg, rgba(254,210,109,0.24) 0%, rgba(253,98,44,0.24) 25%, rgba(249,31,79,0.24) 50%, rgba(202,32,187,0.24) 75%, rgba(47,77,244,0.24) 100%)',

			'&:hover': {
				'& .MuiButton-root': {
					backgroundColor:
						'linear-gradient(0deg, rgba(254,210,109,0.24) 0%, rgba(253,98,44,0.24) 25%, rgba(249,31,79,0.24) 50%, rgba(202,32,187,0.24) 75%, rgba(47,77,244,0.24) 100%)'
				}
			},

			// Mobile
			gridColumn: 'span 1',

			// Tablet
			[theme.breakpoints.up('sm')]: {
				gridColumn: 'span 1'
			},
			// Desktop
			[theme.breakpoints.up('lg')]: {
				gridColumn: 'span 1'
			}
		},
		instagramIcon: {
			minWidth: 'calc(56px - (2 * 4px))',
			borderRadius: 28,
			backgroundColor: '#101116'
		}
	}))()
}

export default useStyles
