// gridWidth
export const gridWidth = '(100vw - (2 * 30px))'
export const gridWidthUpSm = '(100vw - (2 * 75px))'
export const gridWidthUpLg = '(100vw - (2 * 150px))'
// gapWidth
export const gapWidth = '(15px)'
export const gapWidthUpSm = '(15px)'
export const gapWidthUpLg = '(30px)'
// allGapsWidth
export const allGapsWidth = `(11 * ${gapWidth})`
export const allGapsWidthUpSm = `(11 * ${gapWidthUpSm})`
export const allGapsWidthUpLg = `(11 * ${gapWidthUpLg})`
// columnWidth
export const columnWidth = `((${gridWidth} - ${allGapsWidth}) / 12)`
export const columnWidthUpSm = `((${gridWidthUpSm} - ${allGapsWidthUpSm}) / 12)`
export const columnWidthUpLg = `((${gridWidthUpLg} - ${allGapsWidthUpLg}) / 12)`
