import {makeStyles, Theme} from '@material-ui/core/styles'
import {
	columnWidth,
	columnWidthUpLg,
	columnWidthUpSm,
	gapWidth,
	gapWidthUpLg,
	gapWidthUpSm
} from '@utils/constants'
import Styling from '@utils/types/style-typing'

/**
 * Component-scoped styles which should be used as a hook
 * inside HomeAbout functional component
 */

const useStyles: Styling.US = () =>
	makeStyles((theme: Theme) => {
		return {
			homeAbout: {
				display: 'grid',
				gridTemplateColumns: 'repeat(12, 1fr)',
				gridAutoRows: 'minmax(min-content, max-content)',

				backgroundRepeat: 'no-repeat',

				// Mobile
				columnGap: theme.spacing(15 / 8),
				padding: theme.spacing(100 / 8, 0, 0),
				marginLeft: theme.spacing(-30 / 8),
				backgroundSize: `0`,

				// Tablet
				[theme.breakpoints.up('sm')]: {
					columnGap: theme.spacing(15 / 8),
					padding: theme.spacing(140 / 8, 0, 0),
					marginLeft: theme.spacing(-75 / 8),
					// backgroundSize: `calc((5 * ${columnWidthUpSm}) + (4 * ${gapWidthUpSm}))`,
					backgroundSize: `auto calc(100% - 300px)`,
					backgroundPosition: 'top 250px left -50px'
				},
				// Desktop
				[theme.breakpoints.up('lg')]: {
					columnGap: theme.spacing(30 / 8),
					padding: theme.spacing(280 / 8, 0, 130 / 8),
					marginLeft: 0,
					// backgroundSize: `calc((5 * ${columnWidthUpLg}) + (4 * ${gapWidthUpLg}))`,
					backgroundSize: `auto calc(100% - 300px)`,
					backgroundPosition: 'top 100px left'
				},
				// XL Desktop
				[theme.breakpoints.up('xl')]: {
					backgroundSize: `auto calc(100% - 75px)`,
					backgroundPosition: 'top 100px left'
				}
			},
			title: {
				gridRow: '1',
				color: theme.palette.text.primary,

				// Mobile
				gridColumn: '2 / span 11',

				// Tablet
				[theme.breakpoints.up('sm')]: {
					gridColumn: '5 / span 8'
				},
				// Desktop
				[theme.breakpoints.up('lg')]: {
					gridColumn: '7 / span 6'
				}
			},
			body: {
				gridRow: '2',
				margin: theme.spacing(16 / 8, 0),
				color: theme.palette.text.primary,
				fontSize: 24,
				letterSpacing: '-0.33px',
				lineHeight: '28px',

				// Mobile
				gridColumn: '2 / span 11',

				// Tablet
				[theme.breakpoints.up('sm')]: {
					gridColumn: '5 / span 8'
				},
				// Desktop
				[theme.breakpoints.up('lg')]: {
					gridColumn: '7 / span 6'
				}
			},
			bodySecondary: {
				gridRow: '3',
				marginBottom: theme.spacing(40 / 8),
				color: theme.palette.text.secondary,
				fontSize: 16,
				letterSpacing: '-0.22px',
				lineHeight: '24px',

				// Mobile
				gridColumn: '3 / span 10',

				// Tablet
				[theme.breakpoints.up('sm')]: {
					gridColumn: '6 / span 7'
				},
				// Desktop
				[theme.breakpoints.up('lg')]: {
					gridColumn: '8 / span 6'
				}
			},
			materialsTitle: {
				gridRow: '4',
				margin: theme.spacing(16 / 8, 0),
				color: theme.palette.text.primary,

				// Mobile
				gridColumn: '2 / span 11',

				// Tablet
				[theme.breakpoints.up('sm')]: {
					gridColumn: '5 / span 8'
				},
				// Desktop
				[theme.breakpoints.up('lg')]: {
					gridColumn: '7 / span 6'
				}
			},
			materialsImages: {
				gridRow: '5',
				display: 'grid',
				columnGap: theme.spacing(30 / 8),
				rowGap: theme.spacing(30 / 8),
				gridTemplateColumns: 'repeat(3, 1fr)',
				gridAutoRows: 'minmax(min-content, max-content)',

				'& img': {
					maxWidth: '75%'
				},

				// Mobile
				gridColumn: '2 / span 11',

				// Tablet
				[theme.breakpoints.up('sm')]: {
					gridColumn: '5 / span 8'
				},
				// Desktop
				[theme.breakpoints.up('lg')]: {
					gridColumn: '7 / span 6'
				}
			}
		}
	})()

export default useStyles
