import React from 'react'
import PropTypes from 'prop-types'
import {graphql, useStaticQuery} from 'gatsby'
import classNames from 'classnames'
import {Box, Typography} from '@material-ui/core'

import {CommonProps} from '@utils/types'
import {BackdropTypographyComponent} from '@components/index'

import useStyles from './style.hook'

/**
 * HomeAbout
 */
const HomeAbout: React.FC<CommonProps> = ({id, className}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const {
		title,
		titleBackdrop,
		bodySecondary,
		materialsTitle,
		body,
		backgroundImage,
		materialsImages
	} = useStaticQuery(graphql`
		query contentfulHomeAboutQuery {
			contentfulHomeAbout {
				title
				titleBackdrop
				bodySecondary {
					bodySecondary
				}
				materialsTitle
				body {
					body
				}
				backgroundImage {
					file {
						url
					}
				}
				materialsImages {
					title
					file {
						url
					}
				}
			}
		}
	`).contentfulHomeAbout

	return (
		<Box
			id={id}
			className={classNames(classes.homeAbout, className && className)}
			style={{
				backgroundImage: `url(${backgroundImage.file.url})`
			}}
		>
			<BackdropTypographyComponent
				className={classes.title}
				backdropText={titleBackdrop}
				propsToDelegate={{variant: 'h2'}}
			>
				{title}
			</BackdropTypographyComponent>

			<Typography className={classes.body} variant="body1">
				{body.body}
			</Typography>
			<Typography className={classes.bodySecondary} variant="body1">
				{bodySecondary.bodySecondary}
			</Typography>

			<Typography className={classes.materialsTitle} variant="h3">
				{materialsTitle}
			</Typography>
			<Box className={classes.materialsImages}>
				{materialsImages.map((image: {title: string; file: {url: string}}, i: number) => (
					<img key={`material-iamge-${i}`} src={image.file.url} alt={image.title} />
				))}
			</Box>
		</Box>
	)
}

HomeAbout.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string
}

export default HomeAbout
